import { useState } from 'react';

import { useReduxTablePagination } from '../common/useReduxTablePagination';

import { ConsultationsFilterModel } from '~/api/models/consultations/models/ConsultationsFilterModel';
import { ConsultationsPaginatedIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import { useBreakpoints } from '~/utils/hooks/GridHook';
interface Props {
  patientId: number;
  diagnosisId: number;
}
export function useConsultationListing({ patientId, diagnosisId }: Props) {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakpoints();
  const { items, pagination, setPagination } = useReduxTablePagination<
    ConsultationsFilterModel,
    ConsultationsPaginatedIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: 'diagnosisConsultation',
    getItems: (params) => {
      setLoading(true);
      const response = apiGetConsultationsIndex({
        exclude: ['channel', 'calendarEvents', 'insuranceClaim'],
        filter: { patient_id: patientId, diagnosis_id: diagnosisId },
        page: params.page,
        limit: params.limit,
        sort: '-created_at',
      });
      setLoading(false);
      return response;
    },
  });

  return { setPagination, consultations: items, pagination, loading };
}
