import { useNavigation } from '@react-navigation/native';
import React, { useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';

import { useLoginDetails } from '../../api/hooks/accounts/LoginDetails';
import { apiLogin, apiLoginEmail, apiRecover } from '../../api/services/authorisation';
import {
  H5TtmRegularBlack,
  H5TtmSemiBoldTheme,
  H6NsRegularBlack,
  H6NsSemiBoldBlack,
  H6NsSemiBoldSecondaryBlack,
} from '../../components/commonText';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { PassCodeInputComponent, PasscodeInputRef } from '../../components/passCode/PassCodeInputComponent';
import { requiredValidation, passcodeLengthValidation } from '../../services/validationConfig';
import { isNative, whenAppType } from '../../utils/buildConfig';
import { screenName } from '../../utils/screenName';

import { useBiometrics } from '~/api/hooks/BiometricsHook';
import { useUserDetailsRequest } from '~/api/hooks/accounts/UserDetails';
import { OtpChannelEnum } from '~/api/models/otp/constants/OtpChannelEnum';
import { apiSendOtp } from '~/api/services/otp';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { registrationLabels } from '~/utils/labels/registrationLabels';
import { getAccountPhone } from '~/utils/personalDetailsUtils';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { mb5 } from '~/common/commonStyles';

export const LoginPasscodeScreen: React.FC = (): JSX.Element => {
  const navigation = useNavigation();
  const loginDetails = useLoginDetails();
  const { getUserDetails } = useUserDetailsRequest();
  const { deviceIsEnrolled, biometricLogin, deviceBiometricType } = useBiometrics();

  const { isMobile } = useBreakpoints();
  const [loading, setLoading] = useState(false);
  const loggingIn = useRef(false);
  const passcodeInput = useRef<PasscodeInputRef>();

  const { control, handleSubmit, clearErrors, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      passcode: '',
    },
  });

  usePageFocus(() => reset());

  const fullName = useMemo(() => loginDetails.details?.fullName?.trim(), [loginDetails.details?.fullName]);

  const loginSubtitle = useMemo(
    () => (
      <View style={[!isNative() ? { minHeight: 40 } : { width: '100%', alignItems: 'center' }]}>
        {fullName ? (
          isMobile ? (
            <>
              <H6NsRegularBlack maxFontSizeMultiplier={2} style={mb5}>
                Welcome back {fullName}.
              </H6NsRegularBlack>
              <H6NsRegularBlack>
                <H6NsSemiBoldBlack>Please enter the passcode</H6NsSemiBoldBlack> you created during registration to sign
                in.
              </H6NsRegularBlack>
            </>
          ) : (
            <>
              <H6NsRegularBlack>Great to see you again {fullName},</H6NsRegularBlack>
              <H6NsRegularBlack>
                <H6NsSemiBoldBlack>Please enter the passcode</H6NsSemiBoldBlack> you created during registration to sign
                in.
              </H6NsRegularBlack>
            </>
          )
        ) : loginDetails.details?.number && loginDetails.details?.country_code ? (
          <H6NsRegularBlack>
            <H6NsSemiBoldBlack>Please enter your passcode</H6NsSemiBoldBlack> linked with mobile number
            <H6NsSemiBoldSecondaryBlack> {getAccountPhone(loginDetails.details, 'number')}</H6NsSemiBoldSecondaryBlack>
          </H6NsRegularBlack>
        ) : loginDetails.details?.email ? (
          <H6NsRegularBlack>
            <H6NsSemiBoldBlack>Please enter your passcode</H6NsSemiBoldBlack> linked with email
            <H6NsSemiBoldSecondaryBlack> {loginDetails.details.email}</H6NsSemiBoldSecondaryBlack>
          </H6NsRegularBlack>
        ) : (
          <H6NsSemiBoldBlack>{registrationLabels.loginPasscodeSubtitle}</H6NsSemiBoldBlack>
        )}
      </View>
    ),
    [loginDetails?.details?.number, loginDetails?.details?.country_code, loginDetails?.details?.email]
  );

  const login = async (details: { passcode: string }) => {
    try {
      if (loggingIn.current) return;
      loggingIn.current = true;
      setLoading(true);

      if (loginDetails.details.useEmail && loginDetails.details.email) {
        await apiLoginEmail({
          email: loginDetails.details.email,
          passcode: details.passcode,
        });
      } else if (loginDetails.details.country_code && loginDetails.details.number) {
        await apiLogin({
          mobile_phone_number: { country_code: loginDetails.details.country_code, number: loginDetails.details.number },
          passcode: details.passcode,
        });
      }

      const res = await getUserDetails();

      if (res.data?.two_step_verification_type !== OtpChannelEnum.GOOGLE) {
        await apiSendOtp({ channel: res.data?.two_step_verification_type || OtpChannelEnum.SMS }).catch(ErrorAlert);
      }

      navigation.navigate(screenName.OTPScreen_2FA as never);
    } catch (e) {
      passcodeInput.current?.clear();
      setTimeout(clearErrors);

      if (e.message === 'Invalid credentials.') {
        ErrorAlert('Your credentials are invalid');
      } else {
        ErrorAlert(e);
      }
    }
    loggingIn.current = false;
    setLoading(false);
  };

  const handleBiometricLogin = async () => {
    try {
      if (loggingIn.current) return;
      setLoading(true);
      loggingIn.current = true;

      console.log('Login', loginDetails.details);
      await biometricLogin(loginDetails.details);
    } catch (e) {
      console.log('ERROR ->', e);
      ErrorAlert(e);
    } finally {
      loggingIn.current = false;
      setLoading(false);
    }
  };

  const checkForgotPasscode = () => {
    return apiRecover({
      country_code: loginDetails.details.country_code,
      number: loginDetails.details.number,
      email: loginDetails.details.email,
    })
      .then(() => {
        navigation.navigate(screenName.ForgotPasscodeScreen as never);
      })
      .catch(ErrorAlert);
  };

  const buttons = (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexShrink: 1,
      }}>
      <TouchableOpacity onPress={checkForgotPasscode}>
        <H5TtmSemiBoldTheme maxFontSizeMultiplier={1.8}>{registrationLabels.forgotYourPasscode}</H5TtmSemiBoldTheme>
      </TouchableOpacity>
      <H5TtmRegularBlack maxFontSizeMultiplier={1.8}> or </H5TtmRegularBlack>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate(screenName.LoginScreen as never);
        }}>
        <H5TtmSemiBoldTheme maxFontSizeMultiplier={1.8}>
          {whenAppType({
            whenDoctor: registrationLabels.changedPhoneNumber,
            whenPatient: registrationLabels.changedPhoneNumber,
            else: registrationLabels.changeCredential,
          })}
        </H5TtmSemiBoldTheme>
      </TouchableOpacity>
    </View>
  );

  return (
    <EnhancedRegistrationLayout
      compressed={!isNative()}
      flexContent
      hideProgress
      hideRequired
      title={isMobile ? null : registrationLabels.loginTitle}
      showAppLogo={isMobile}>
      {loginSubtitle}
      <View style={{ display: 'flex', flex: 1, width: '100%' }}>
        <Controller
          name="passcode"
          control={control}
          render={({ field: { onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <PassCodeInputComponent
              ref={passcodeInput}
              hasTouchFaceId={deviceIsEnrolled}
              biometricType={deviceBiometricType.current}
              autoTrigger
              onChange={onChange}
              onEnter={() => handleSubmit(login)()}
              loginWithTouchId={handleBiometricLogin}
              editable={!loading}
              errorMessage={isSubmitted && error?.message}
              loading={loading}
            />
          )}
          rules={{
            minLength: passcodeLengthValidation(4),
            required: requiredValidation('Passcode'),
          }}
        />
        {buttons}
      </View>
    </EnhancedRegistrationLayout>
  );
};
