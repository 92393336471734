import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { KeyboardAvoidingView, Modal, Platform, StyleSheet, View } from 'react-native';

import { RescheduleAppointmentMobileModal } from './RescheduleAppointmentMobileModal';
import { CancelContinueFooter } from './buttons/CancelContinueFooter';
import TimePicker from './common/TimePicker';
import { Button, OutlineButton } from './commonButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from './commonText';
import { modalStyles } from './modals/ModalStyling';
import { ModalName } from './modals/constants/ModalNames';
import { BaseModalProps } from './modals/interfaces/ModalProps';
import { NotificationIcon } from './svgImages';

import { useAppointmentReschedule } from '~/api/hooks/consultations/AppointmentRescheduleHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiGetConsultation } from '~/api/services/consultations';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { flex1, mb5 } from '~/common/commonStyles';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { greaterThanValidation, requiredValidation } from '~/services/validationConfig';
import { colors } from '~/utils/colors';
import { LARAVEL_ATOM, parseDateTime, TIME_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { InprogressAlert } from '~/common/commonMethods';
import { ErrorAlert } from './modals/ErrorAlert';

interface Props extends BaseModalProps {
  consultationId?: number;
  consultation?: ConsultationModel;
  onHide: () => void;
}

export const AppointmentNotificationModal: React.FC<Props> = ({ onHide, consultationId, consultation }) => {
  const [isLate, setIsLate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useBreakpoints();
  const { registerModal, openModal } = useModalManager();
  const [localConsultation, setConsultation] = useState<ConsultationModel>();
  const { control, submitRescheduleAppointment, handleSubmit } = useAppointmentReschedule();
  const [errorResponse, setErrorResponse] = useState<ErrorResponse>();
  const { closeModalByName } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.RescheduleAppointmentMobile, RescheduleAppointmentMobileModal);
  }, []);

  useEffect(() => {
    if (consultation) {
      setConsultation(consultation);
    } else if (consultationId) {
      apiGetConsultation({
        id: consultationId,
      })
        .then((res) => setConsultation(res.data))
        .catch(() => {
          onHide();
        });
    }
  }, [consultationId, consultation]);

  const { top } = useSafeAreaInsets();

  const message = useMemo(() => {
    if (!localConsultation?.start_at) return ['', ''];
    const difference = moment().diff(localConsultation.start_at, 'minutes');
    const absDifference = Math.abs(difference);
    if (difference < 0) {
      return [
        `Your scheduled appointment is starting in ${absDifference} ${absDifference === 1 ? 'minute' : 'minutes'}`,
        'Will you be on time?',
      ];
    } else {
      return [
        `Your scheduled appointment should have started ${absDifference} ${
          absDifference === 1 ? 'minute' : 'minutes'
        } ago`,
        'Are you still on time?',
      ];
    }
  }, [localConsultation?.start_at]);

  const closeChangeRequestModals = () => {
    closeModalByName(ModalName.AppointmentNotification);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setErrorResponse(null);
      await submitRescheduleAppointment(localConsultation);
      closeChangeRequestModals();

      InprogressAlert(
        ['We have notified the patient about the time change and will let you know if it was accepted or not.'],
        {
          title: 'We notified the patient.',
        }
      );
    } catch (e) {
      if (typeof e?.message !== 'string' || (e.message as string).indexOf('No query results for model ') !== 0) {
        closeChangeRequestModals();
        ErrorAlert(
          'Seems the consultation request has already expired an you are not able to reschedule.',
          'Consultation no longer available'
        );
      } else {
        setErrorResponse(e);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!localConsultation) return null;

  return (
    <Modal animationType="fade" transparent visible onRequestClose={null}>
      <KeyboardAvoidingView
        style={{ display: 'flex', flex: 1, top: Math.max(top, 20) }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <View style={[modalStyles.modalWrapper]}>
          <View style={[flex1, { display: 'flex', height: '100%', paddingHorizontal: 20 }]}>
            <View pointerEvents="auto" style={[styles.contentContainer, { padding: 20 }]}>
              <View style={{ alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 20,
                    alignItems: 'center',
                    paddingBottom: isMobile ? 20 : 0,
                    flex: isMobile ? undefined : 1,
                  }}>
                  <View>
                    <NotificationIcon width={30} height={30} />
                  </View>
                  <View style={{ display: 'flex', flex: 1, flexDirection: isMobile ? 'column' : 'row', gap: 15 }}>
                    <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <H6NsRegularBlack style={mb5}>{message[0]}</H6NsRegularBlack>
                      <H6NsSemiBoldBlack>{message[1]}</H6NsSemiBoldBlack>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    paddingLeft: isMobile ? 50 : 0,
                    minWidth: isMobile ? undefined : 140,
                  }}>
                  <View style={flex1}>
                    <Button
                      funCallback={() => {
                        setIsLate(false);
                        onHide();
                      }}
                      label="Yes"
                      disabled={isLate}
                    />
                  </View>
                  <View style={flex1}>
                    <OutlineButton
                      funCallback={() => {
                        if (isMobile)
                          openModal(ModalName.RescheduleAppointmentMobile, { consultation: localConsultation });
                        else setIsLate(true);
                      }}
                      label="No"
                      disabled={isLate}
                    />
                  </View>
                </View>
              </View>

              {isLate && !isMobile ? (
                <View>
                  <View style={{ marginBottom: 15 }}>
                    <Controller
                      name="time"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TimePicker
                          label="Choose time"
                          value={value}
                          onChangeValue={onChange}
                          errorMessage={error?.message || errorResponse?.message}
                          error={!!error || !!errorResponse}
                        />
                      )}
                      rules={{
                        required: requiredValidation('Appointment time'),
                        validate: {
                          greaterThanValidation: greaterThanValidation(
                            () =>
                              parseDateTime(localConsultation.start_at, {
                                parseFormat: LARAVEL_ATOM,
                                outputFormat: TIME_FORMAT,
                              }),
                            'Appointment Start Time',
                            'Selected Time'
                          ),
                        },
                      }}
                    />
                  </View>
                  <CancelContinueFooter
                    style={{ alignSelf: 'center', flex: 1, marginTop: 10 }}
                    confirmTitle={labels.update}
                    onCancel={() => setIsLate(false)}
                    onConfirm={handleSubmit(onSubmit)}
                    disabled={loading}
                  />
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
    maxWidth: 548,
    maxHeight: '100%',
    alignSelf: 'center',
    borderRadius: 5,
    backgroundColor: colors.lightPurple2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    display: 'flex',
  },
  row: { alignItems: 'center', justifyContent: 'center', marginHorizontal: 0, marginVertical: 10 },
});
