import React from 'react';
import { View } from 'react-native';
import { useReduxTablePagination } from '~/api/hooks/common/useReduxTablePagination';
import { AuditLogsResponse } from '~/api/models/audit/responses/AuditLogsResponse';
import { apiGetHealthProfileAuditLogs } from '~/api/services/healthProfile';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { AuditTable } from '~/components/audit/AuditTable';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { H6NsRegularBlack } from '~/components/commonText';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { ActivityIndicator } from 'react-native-paper';
import { colors } from '~/utils/colors';
import { flex1 } from '~/common/commonStyles';

interface Props {
  id: number;
}

export const ScrollableHealthProfileLog: React.FC<Props> = ({ id }) => {
  const { isMobile } = useBreakpoints();

  const { items, pagination, setPagination, loading } = useReduxTablePagination<any, AuditLogsResponse>({
    isInfiniteScroll: isMobile,
    tableName: 'auditLogs',
    getItems: async (params) => {
      if (id) {
        const response = await apiGetHealthProfileAuditLogs({
          id,
          req: { page: params.page, limit: params.limit || 10, sort: '-created_at' },
        });
        return response;
      }
      return null;
    },
  });

  if (!id) {
    return (
      <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
        <H6NsRegularBlack>No health profile was found.</H6NsRegularBlack>
      </View>
    );
  }

  const pageChanged = (pageInfo: TablePaginationInfo) => {
    setPagination(pageInfo);
  };

  return (
    <AuditTable
      data={items}
      pagination={pagination}
      onPageChanged={pageChanged}
      loading={loading}
      listStyle={!isMobile ? null : { paddingHorizontal: MOBILE_HORIZONTAL_SPACE }}
    />
  );
};
