import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import { ConsultationTypeEnum, ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { openLink } from '~/common/commonMethods';
import { flex1, mb10, mt15, mt20 } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { Splitter } from '~/components/misc/Splitter';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { Row, Column } from '~/theme/components/grid';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_FORMAT, TIME_FORMAT, SHORT_DATE_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { appointmentLabels } from '~/utils/labels/appointments';

interface Props {
  consultation: ConsultationModel;
  formattedAddress?: string;
  loading?: boolean;
}

export const AppointmentDetails: React.FC<Props> = ({ consultation, formattedAddress, loading }) => {
  const { isMobile } = useBreakpoints();

  const hasStartTime = useMemo(
    () =>
      consultation?.type === ConsultationTypeEnum.SCHEDULED_APPOINTMENT ||
      (consultation?.type === ConsultationTypeEnum.HOME_VISIT && !!consultation.start_at),
    [consultation]
  );

  const isHomeVisit = useMemo(() => consultation?.type === ConsultationTypeEnum.HOME_VISIT, [consultation]);

  const symptoms = useMemo(
    () => consultation?.patient_symptoms?.map(extractClinicalTermDescription)?.join(', ') ?? '',
    [consultation]
  );

  return (
    <View style={[styles.detailsContainer, isMobile ? { backgroundColor: colors.white, padding: 0 } : null]}>
      {!consultation ? (
        loading ? (
          <Row style={flex1}>
            <Column alignSelf="center">
              <LoadingActivityIndicator />
            </Column>
          </Row>
        ) : (
          <Row style={flex1}>
            <Column alignSelf="center">
              <H6NsRegularBlack>Something went wrong while we were creating your appointment request.</H6NsRegularBlack>
              <H6NsRegularBlack>Please try a different time or doctor and try again.</H6NsRegularBlack>
              <H6NsRegularBlack>If the problem persists please contact us.</H6NsRegularBlack>
            </Column>
          </Row>
        )
      ) : (
        <>
          <Row>
            <Column span={{ xs: 12, lg: undefined }} style={mb10}>
              <ProfileDetailsComponent account={consultation?.doctor || consultation?.clinic} isDoctor />
            </Column>
            <Splitter secondaryColor={!isMobile} vertical />
            <Column span={{ xs: 12, lg: undefined }} style={mb10}>
              <ProfileDetailsComponent account={consultation?.patient} />
            </Column>
          </Row>
          <Splitter secondaryColor={!isMobile} />
          <Row>
            <Column>
              <H6NsRegularBlack>
                Type of consultation:{' '}
                <H6NsSemiBoldBlack>{ConsultationTypeMapping[consultation.type]}</H6NsSemiBoldBlack>
              </H6NsRegularBlack>
            </Column>
          </Row>
          <Splitter secondaryColor={!isMobile} />
          <Row>
            {hasStartTime ? (
              <>
                <Column span={{ xs: 12, lg: undefined }}>
                  <H6NsRegularBlack>
                    Date:{' '}
                    <H6NsSemiBoldBlack>
                      {parseDateTime(consultation.start_at, {
                        outputFormat: DATE_FORMAT,
                      })}
                    </H6NsSemiBoldBlack>
                  </H6NsRegularBlack>
                </Column>
                <Splitter secondaryColor={!isMobile} vertical />

                <Column span={{ xs: 12, lg: undefined }}>
                  <H6NsRegularBlack>
                    Time:{' '}
                    <H6NsSemiBoldBlack>
                      {parseDateTime(consultation.start_at, {
                        outputFormat: TIME_FORMAT,
                      })}
                    </H6NsSemiBoldBlack>
                  </H6NsRegularBlack>
                </Column>
                <Splitter secondaryColor={!isMobile} vertical />
              </>
            ) : null}
            {!hasStartTime && isHomeVisit ? (
              <>
                <Column span={{ xs: 12, lg: undefined }}>
                  <H6NsRegularBlack>Available times:</H6NsRegularBlack>
                  {consultation.patient_availabilities?.map((availability, index) => (
                    <H6NsSemiBoldBlack key={index}>
                      {parseDateTime(availability.from, {
                        outputFormat: `${SHORT_DATE_FORMAT} [from] ${TIME_FORMAT}`,
                      })}
                      {parseDateTime(availability.to, {
                        outputFormat: ` [to] ${TIME_FORMAT}`,
                      })}
                    </H6NsSemiBoldBlack>
                  ))}
                </Column>
              </>
            ) : null}
            <Column span={{ xs: 12, lg: undefined }}>
              {isHomeVisit ? (
                <>
                  <H6NsRegularBlack>Address:</H6NsRegularBlack>
                  <H6NsSemiBoldBlack>
                    {formattedAddress ||
                      (consultation.patient_location
                        ? consultation.patient_location.formatted_address ||
                          `Coordinate ${consultation.patient_location.lat}, ${consultation.patient_location.lng}`
                        : '')}
                  </H6NsSemiBoldBlack>
                </>
              ) : (
                <H6NsRegularBlack>
                  Channel: <H6NsSemiBoldBlack>{consultation?.channel?.name}</H6NsSemiBoldBlack>
                </H6NsRegularBlack>
              )}
            </Column>
          </Row>
          <Splitter secondaryColor={!isMobile} />
          <Row>
            <Column>
              <View>
                <H6NsSemiBoldBlack>Reason for consultation</H6NsSemiBoldBlack>
                <H6NsRegularBlack>{consultation.reason || appointmentLabels.noReason}</H6NsRegularBlack>
              </View>

              {consultation?.reason_media?.length ? (
                <View style={mt15}>
                  {consultation?.reason_media?.map((media, index) => (
                    <LinkButton
                      key={index}
                      style={[mb10, { paddingHorizontal: 0 }]}
                      title={media.name}
                      onPress={() => openLink(media.url)}
                    />
                  ))}
                </View>
              ) : null}

              {symptoms ? (
                <View style={mt20}>
                  <H6NsSemiBoldBlack>Symptoms</H6NsSemiBoldBlack>
                  <H6NsRegularBlack>{symptoms}</H6NsRegularBlack>
                </View>
              ) : null}
              <Row style={mt20}>
                <Column>
                  <H6NsSemiBoldBlack>
                    Require sick leave certificate:{' '}
                    <H6NsRegularBlack>
                      {consultation?.is_sick_leave_certificate_required ? 'Yes' : 'No'}
                    </H6NsRegularBlack>
                  </H6NsSemiBoldBlack>
                </Column>
              </Row>
            </Column>
          </Row>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  detailsContainer: {
    backgroundColor: colors.lightPurple,
    padding: 20,
    borderRadius: 6,
    minHeight: 400,
  },
});
