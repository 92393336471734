import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { TitledLinkButton } from '../buttons/TitledLinkButton';
import { HomeVisitNavigationButton } from '../common/consultations/buttons/HomeVisitNavigationButton';
import { ScheduledAndOnDemandNavigationButton } from '../common/consultations/buttons/ScheduledAndOnDemandNavigationButton';
import { DetailKeys, PatientListedDetails } from '../common/patient/PatientListedDetails';
import { OutlineButton } from '../commonButton';
import { H6NsRegularBlack, H6NsRegularSecondaryBlack, H6NsSemiBoldSecondaryBlack } from '../commonText';
import { Splitter } from '../misc/Splitter';
import { BackNavigation } from '../navigation/BackNavigation';
import { ProfileDetailsComponent } from '../profile/ProfileDetailsComponent';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { ConsultationTypeEnum, ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mt10, mt25 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { AppointmentProvider } from '~/providers/appointment/AppointmentProvider';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, NUM_DATE_FORMAT, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useNavigationHealthProfile } from '~/utils/hooks/AppNavigationHook';
import { useAppointmentState } from '~/utils/hooks/appointments/AppointmentStateHook';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { getAccountID, getAccountName, getAccountPhone } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';
import { LoadingActivityIndicator } from '../loading/LoadingActivityIndicator';

interface Props extends BaseModalProps {
  consultationId: number;
}

const detailKeys = (consultation: ConsultationModel): DetailKeys[] => [
  {
    label: labels.fullName,
    key: 'full_name',
    format: (account?: AccountModel) => {
      return getAccountName(account);
    },
    style: { fontWeight: 'bold' },
  },
  {
    label: labels.patientID,
    key: 'patientID',
    format: (account?: AccountModel) => {
      return getAccountID(account);
    },
  },
  {
    label: labels.phone,
    key: 'phone',
    format: (account: AccountModel) => {
      return getAccountPhone(account);
    },
    style: { color: colors.info },
  },
  {
    label: labels.address,
    key: 'address',
    format: (account: AccountModel) => consultation.patient_location.formatted_address,
  },
  {
    label: labels.date,
    key: 'date',
    format: (_) => parseDateTime(consultation.start_at, { outputFormat: NUM_DATE_FORMAT }),
  },
  {
    label: labels.bookingTime,
    key: 'booking_time',
    format: (_) => parseDateTime(consultation.start_at, { outputFormat: TIME_FORMAT }),
  },
];

const AppointmentModalContent: React.FC<Props> = ({ onHide, consultationId }) => {
  const { consultation, cancelAppointment, loading } = useAppointmentContext();
  const { isCancellable } = useAppointmentState(consultation);
  const [healthProfileLink, setHealthProfileLink] = useState<To<ReactNavigation.RootParamList>>({} as never);
  const { link } = useNavigationHealthProfile();

  useEffect(() => {
    if (consultation?.patient?.id) {
      const generatedLink = link(consultation.patient.id);
      setHealthProfileLink(generatedLink);
    }
  }, [consultation]);

  const isHomeVisit = useMemo(() => {
    return consultation?.type === ConsultationTypeEnum.HOME_VISIT;
  }, [consultation]);

  const modalButtons =
    !consultation || loading
      ? null
      : consultation.type === ConsultationTypeEnum.HOME_VISIT
      ? [
          <View style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, paddingBottom: 20 }}>
            <HomeVisitNavigationButton allowDetailView onPress={onHide} />
          </View>,
        ]
      : [
          <View style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, paddingBottom: 20 }}>
            <ScheduledAndOnDemandNavigationButton onPress={onHide} />
          </View>,
        ];

  const HomeVisitContent = () => {
    return (
      <View>
        <BackNavigation title={labels.homeVisit} onPress={onHide} />
        <View
          style={{
            paddingVertical: 10,
            borderRadius: 6,
            flex: 1,
          }}>
          <View style={{ paddingHorizontal: 20 }}>
            <PatientListedDetails hideProfileImage account={consultation?.patient} details={detailKeys(consultation)} />
          </View>

          <Splitter />
        </View>
        <H6NsRegularBlack>The location is enabled for patient to see the estimated time of arrival.</H6NsRegularBlack>
        <TitledLinkButton style={mt10} title="Health Profile" to={healthProfileLink} onPress={onHide} />

        {isDoctorVersion() ? (
          <TitledLinkButton
            style={mt10}
            title="Existing Diagnoses"
            to={{ screen: screenName.PatientDetails as never, params: { id: consultation.patient.id } as never }}
            onPress={onHide}
          />
        ) : null}
      </View>
    );
  };

  const NotHomeVisitContent = () => {
    return (
      <View>
        <BackNavigation title={labels.appointmentDetails} onPress={onHide} />
        <View style={{ marginTop: 15 }}>
          <H6NsRegularSecondaryBlack style={{ flex: 0.35 }}>
            {labels.consultationId + ': '}
            <H6NsSemiBoldSecondaryBlack>{consultation?.id}</H6NsSemiBoldSecondaryBlack>
          </H6NsRegularSecondaryBlack>
        </View>

        <View style={styles.horizontalLine} />

        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
          <ProfileDetailsComponent account={consultation?.doctor ?? consultation?.clinic} />
        </View>

        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ProfileDetailsComponent account={consultation?.patient} />
        </View>

        <View style={styles.horizontalLine} />

        <View>
          <H6NsRegularBlack>
            {labels.typeOfConsultation}:{' '}
            <H6NsSemiBoldSecondaryBlack>{ConsultationTypeMapping[consultation?.type]}</H6NsSemiBoldSecondaryBlack>
          </H6NsRegularBlack>
        </View>

        <View style={styles.horizontalLine} />

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <H6NsRegularBlack>
            Date:{' '}
            <H6NsSemiBoldSecondaryBlack>
              {parseDateTime(consultation?.start_at || consultation?.request_submitted_at, {
                outputFormat: DATE_FORMAT,
              })}
            </H6NsSemiBoldSecondaryBlack>
          </H6NsRegularBlack>
          <H6NsRegularBlack>
            Time:{' '}
            <H6NsSemiBoldSecondaryBlack>
              {parseDateTime(consultation?.start_at || consultation?.request_submitted_at, {
                outputFormat: TIME_FORMAT,
              })}
            </H6NsSemiBoldSecondaryBlack>
          </H6NsRegularBlack>
        </View>

        <View style={styles.horizontalLine} />

        <H6NsSemiBoldSecondaryBlack>{labels.reasonForConsultation}</H6NsSemiBoldSecondaryBlack>
        <H6NsRegularBlack>{consultation?.reason || appointmentLabels.noReason}</H6NsRegularBlack>

        <TitledLinkButton style={mt25} title="Health Profile" to={healthProfileLink} onPress={onHide} />

        {isDoctorVersion() ? (
          <TitledLinkButton
            style={mt10}
            title="Existing Diagnoses"
            to={{ screen: screenName.PatientDetails as never, params: { id: consultation?.patient?.id } as never }}
            onPress={onHide}
          />
        ) : null}
      </View>
    );
  };

  return (
    <ModalContainer animationType="slide" hideOnBackground fullScreen edge onHide={onHide} buttons={modalButtons}>
      <View style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, paddingTop: 20 }}>
        {!consultation || loading ? (
          <LoadingActivityIndicator />
        ) : (
          <>
            {isHomeVisit ? HomeVisitContent() : NotHomeVisitContent()}
            {isCancellable ? (
              <OutlineButton
                danger
                label={labels.cancelAppointment}
                funCallback={() => {
                  cancelAppointment();
                  onHide();
                }}
                style={mt10}
              />
            ) : null}
          </>
        )}
      </View>
    </ModalContainer>
  );
};

export const AppointmentDetailsModal: React.FC<Props> = (props) => {
  return (
    <AppointmentProvider consultationId={props.consultationId}>
      <AppointmentModalContent {...props} />
    </AppointmentProvider>
  );
};

const styles = StyleSheet.create({
  instructionsStyling: {
    fontSize: 13,
    marginBottom: 5,
    paddingLeft: 15,
  },
  horizontalLine: {
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingBottom: 16,
    marginBottom: 16,
  },
  borderRight: {
    borderRightColor: colors.lightPurple2,
    borderRightWidth: 1,
  },
});
