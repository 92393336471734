import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { DashboardCard } from '../common/DashboardCard';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1, mb5, mr15 } from '~/common/commonStyles';
import { H5TtmSemiBoldInfo, H6NsSemiBoldBlack, SmallNsRegularSecondaryBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { Column } from '~/theme/components/grid';
import { LARAVEL_DATE_TIME_SHORT, parseDateTime } from '~/utils/dateAndTime';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { labels } from '~/utils/labels';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  style?: StyleProp<ViewStyle>;
}
export const UpcomingAppointmentCard: React.FC<Props> = ({ style }): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [appointment, setAppointment] = useState<ConsultationModel>(undefined);
  const { showDetails } = useShowAppointment();

  const getUpcoming = async () => {
    try {
      setLoading(true);

      const appointmentRes = await apiGetConsultationsIndex({
        exclude: ['patient', 'insuranceClaim', 'calendarEvents', 'clinicalTerms', 'channel', 'clinicAccount'],
        filter: {
          state: [ConsultationStateEnum.Scheduled, ConsultationStateEnum.PendingPayment],
          after: moment().startOf('D').format(LARAVEL_DATE_TIME_SHORT),
        },
        sort: 'start_at',
      });

      if (appointmentRes.data.data.length > 0) {
        setAppointment(appointmentRes.data.data[0]);
      } else {
        setAppointment(undefined);
      }
    } catch {}
    setLoading(false);
  };

  usePageFocus(() => {
    getUpcoming();
  }, []);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.PATIENT_APPOINTMENTS_CHANGED, getUpcoming);
    return removeListener;
  }, []);

  if (!appointment) return null;
  return (
    <Column span={{ xs: 12, md: 6, lg: 'flex' }} style={style}>
      <DashboardCard
        white
        shadow
        style={[styles.dashboardCard]}
        title="Upcoming"
        onPress={
          appointment
            ? () => {
                showDetails({ consultation: appointment });
              }
            : null
        }>
        <View style={styles.items}>
          {appointment ? (
            <>
              <View style={mr15}>
                <View style={{ justifyContent: 'flex-start' }}>
                  <ProfileImageComponent account={appointment.doctor} />
                </View>
              </View>
              <View style={[flex1, { flexDirection: 'column' }]}>
                <View style={{ justifyContent: 'flex-start' }}>
                  <H6NsSemiBoldBlack style={mb5}>{getAccountName(appointment?.doctor)}</H6NsSemiBoldBlack>
                </View>
                <View style={{ justifyContent: 'flex-start' }}>
                  <H5TtmSemiBoldInfo>
                    {parseDateTime(appointment.start_at, { outputFormat: `Do MMMM [at] HH:mm` })}
                  </H5TtmSemiBoldInfo>
                </View>
              </View>
            </>
          ) : (
            <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center', minHeight: 50 }}>
              {!loading ? (
                <SmallNsRegularSecondaryBlack>{labels.noUpcomingAppointments}</SmallNsRegularSecondaryBlack>
              ) : (
                <LoadingActivityIndicator style={{ padding: 0 }} />
              )}
            </View>
          )}
        </View>
      </DashboardCard>
    </Column>
  );
};

const styles = StyleSheet.create({
  dashboardCard: {
    width: '100%',
    flex: 1,
  },
  items: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
    marginTop: 15,
  },
});
