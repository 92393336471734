import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useReduxTablePagination } from '../common/useReduxTablePagination';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationsFilterModel } from '~/api/models/consultations/models/ConsultationsFilterModel';
import { ConsultationsPaginatedIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const TABLE_NAME = 'clinicPastAppointments';
export function useClinicPastRequestsList() {
  const [loading, setLoading] = useState(true);
  const { isMobile } = useBreakpoints();

  const { control, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      stateFilter: [],
    },
  });

  const { items, pagination, setPagination, updateData, resetTable } = useReduxTablePagination<
    ConsultationsFilterModel,
    ConsultationsPaginatedIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: TABLE_NAME,
    getItems: async (params) => {
      setLoading(true);
      const data = getValues();

      const response = await apiGetConsultationsIndex({
        exclude: ['insuranceClaim', 'calendarEvents'],
        filter: {
          state: data.stateFilter?.length
            ? data.stateFilter
            : [
                ConsultationStateEnum.Cancelled,
                ConsultationStateEnum.RequestRejected,
                ConsultationStateEnum.RequestIgnored,
                ConsultationStateEnum.Started,
                ConsultationStateEnum.Ended,
                ConsultationStateEnum.Submitted,
              ],
        },
        page: params.page,
        sort: '-updated_at',
      });
      setLoading(false);
      return response;
    },
  });

  return { requests: items, pagination, setPagination, loading, control, updateData, resetTable };
}
