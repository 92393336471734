import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import TimePicker from './common/TimePicker';
import { Button, OutlineButton } from './commonButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from './commonText';
import { ModalContainer } from './modals/ModalContainer';
import { ModalName } from './modals/constants/ModalNames';
import { BaseModalProps } from './modals/interfaces/ModalProps';

import { useAppointmentReschedule } from '~/api/hooks/consultations/AppointmentRescheduleHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { requiredValidation, greaterThanValidation } from '~/services/validationConfig';
import { Column, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { TIME_FORMAT, parseDateTime, LARAVEL_ATOM } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { InprogressAlert } from '~/common/commonMethods';
import { ErrorAlert } from './modals/ErrorAlert';

interface Props extends BaseModalProps {
  consultation: ConsultationModel;
  onHide: () => void;
}

export const RescheduleAppointmentMobileModal: React.FC<Props> = ({ consultation, onHide }) => {
  const { closeModalByName } = useModalManager();
  const { control, submitRescheduleAppointment, handleSubmit } = useAppointmentReschedule();
  const [errorResponse, setErrorResponse] = useState<ErrorResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const closeChangeRequestModals = () => {
    closeModalByName(ModalName.RescheduleAppointmentMobile);
    closeModalByName(ModalName.AppointmentNotification);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setErrorResponse(null);
      await submitRescheduleAppointment(consultation);
      closeChangeRequestModals();

      InprogressAlert(
        ['We have notified the patient about the time change and will let you know if it was accepted or not.'],
        {
          title: 'We notified the patient.',
        }
      );
    } catch (e) {
      if (typeof e?.message !== 'string' || (e.message as string).indexOf('No query results for model ') !== 0) {
        closeChangeRequestModals();
        ErrorAlert(
          'Seems the consultation request has already expired an you are not able to reschedule.',
          'Consultation no longer available'
        );
      } else {
        setErrorResponse(e);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalContainer
      animationType="slide"
      hideNotch={false}
      hideOnBackground
      onHide={onHide}
      buttons={[
        <Row style={{ paddingBottom: 30 }}>
          <Column style={{ paddingRight: 5 }}>
            <OutlineButton funCallback={onHide} label="Cancel" />
          </Column>
          <Column style={{ paddingLeft: 5 }}>
            <Button funCallback={handleSubmit(onSubmit)} label="Update" />
          </Column>
        </Row>,
      ]}
      modalStyle={{ backgroundColor: colors.lightPurple, justifyContent: 'center', alignContent: 'center' }}>
      <Grid>
        <Row>
          <Column>
            <H6NsSemiBoldBlack>Reschedule the appointment</H6NsSemiBoldBlack>
          </Column>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Column>
            <H6NsRegularBlack style={{ fontSize: 12 }}>
              {labels.scheduledAppointmentIn}5 minutes. {labels.reSchedule}
            </H6NsRegularBlack>
          </Column>
        </Row>

        <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Column>
            <Controller
              name="time"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <TimePicker
                  label="Choose time"
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message || errorResponse?.message}
                  error={!!error || !!errorResponse}
                />
              )}
              rules={{
                required: requiredValidation('Appointment time'),
                validate: {
                  greaterThanValidation: greaterThanValidation(
                    () =>
                      parseDateTime(consultation.start_at, {
                        parseFormat: LARAVEL_ATOM,
                        outputFormat: TIME_FORMAT,
                      }),
                    'Appointment Start Time',
                    'Selected Time'
                  ),
                },
              }}
            />
          </Column>
        </Row>
      </Grid>
    </ModalContainer>
  );
};
