// import { ExpoScreenCaptureIosView } from '@threls/expo-screen-capture-ios';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useFonts } from 'expo-font';
import { setStatusBarStyle } from 'expo-status-bar';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AppState, AppStateStatus, StyleSheet, View } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react-native';
import Constants from 'expo-constants';

import RootNavigation from './src/navigation';
import store from './src/redux/store';

import { getToken, verifyStoredToken } from '~/api/axios/interceptors';
import { useSubscriptionLoading } from '~/api/hooks/subscriptions/SubscriptionManagementHook';
import { initChannels } from '~/integrations/channels/PusherChannels';
import { MetaTagComponent } from '~/integrations/html/MetaTagComponent';
import { withIAPContext, setup } from '~/integrations/inAppPurchases';
import { PushNotificationProvider } from '~/integrations/pushNotifications/PushNotificationProvider';
import ScreenCapture from '~/integrations/screenCapture';
import { StripeProvider } from '~/integrations/stripe/provider';
import { LoadingScreen } from '~/pages/loading/Loading';
import { DefaultPaperTheme } from '~/theme/paper/DefaultPaperTheme';
import { useStoreReferrer } from '~/utils/hooks/StoreReferrerHook';
import log from '~/utils/logger';
import { fullPageLoadingSelector } from '~/redux/reducers/overlayReducer';
import { CountryListEnum, useCountryListing } from '~/api/hooks/referenceData/CountryListing';
import { useCheckAppVersion } from '~/api/hooks/CheckAppVersion';

interface AppContentProps {
  initialLoading?: boolean;
  appStateVisible: AppStateStatus;
}
const AppContent: React.FC<AppContentProps> = ({ initialLoading, appStateVisible }) => {
  useCountryListing(CountryListEnum.PRE_FETCH);
  useCheckAppVersion();

  const showLoading = useSelector(fullPageLoadingSelector);
  const appleAppId = Constants.expoConfig.extra.appleAppId;

  const showLoadingDueToAppState = useMemo(() => {
    return appStateVisible !== 'active' && appStateVisible !== 'background' && !!getToken();
  }, [appStateVisible]);

  return (
    <>
      {appleAppId ? <MetaTagComponent name="apple-itunes-app" content={`app-id=${appleAppId}`} /> : null}
      {!initialLoading ? (
        <View style={styles.safeAreaChild}>
          <RootNavigation />
        </View>
      ) : null}
      {showLoadingDueToAppState || showLoading || initialLoading ? (
        <View style={[styles.screenCaptureView]}>
          <LoadingScreen />
        </View>
      ) : null}
    </>
  );
};

const App = () => {
  useSubscriptionLoading();
  useStoreReferrer();
  ScreenCapture.usePreventScreenCapture();

  const [fontsLoaded] = useFonts({
    'TitilliumWeb-Regular': require('./assets/fonts/TitilliumWeb-Regular.ttf'),
    NotoSans: require('./assets/fonts/NotoSans.ttf'),
    'NotoSans-Bold': require('./assets/fonts/NotoSans-Bold.ttf'),
    'NotoSans-BoldItalic': require('./assets/fonts/NotoSans-BoldItalic.ttf'),
    'NotoSans-Italic': require('./assets/fonts/NotoSans-Italic.ttf'),
    'TitilliumWeb-SemiBold': require('./assets/fonts/TitilliumWeb-SemiBold.ttf'),
  });

  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [isTokenVerified, setIsTokenVerified] = useState(false);

  Sentry.init({
    dsn: Constants.expoConfig.extra.sentryKey,
    debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
    environment: Constants.expoConfig.extra.iapEnv,
    //key pass the enviroenm
    // uncomment the line below to enable Spotlight (https://spotlightjs.com)
    // enableSpotlight: __DEV__,
  });

  const verifyToken = () => {
    verifyStoredToken().then((verified) => {
      setIsTokenVerified(verified);
      setTimeout(() => {
        setIsTokenVerified(true);
      });
    });
  };

  useEffect(() => {
    verifyToken();
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      appState.current = nextAppState;
      setAppStateVisible(appState.current);
      if (nextAppState === 'active') {
        verifyToken();
      }
    });

    initChannels().catch((e) => {
      log.warn(e);
    });

    setStatusBarStyle('dark');

    return () => {
      subscription.remove();
    };
  }, []);

  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <PaperProvider
          theme={DefaultPaperTheme}
          settings={{
            icon: (props) => (
              <MaterialCommunityIcons
                color={props.color}
                name={props.name as any}
                size={props.size}
                testID={props.testID}
                allowFontScaling={props.allowFontScaling}
              />
            ),
          }}>
          <StripeProvider>
            <PushNotificationProvider>
              {/* <ExpoScreenCaptureIosView style={styles.screenCaptureView}> */}
              <AppContent appStateVisible={appStateVisible} initialLoading={!fontsLoaded || !isTokenVerified} />
              {/* </ExpoScreenCaptureIosView> */}
            </PushNotificationProvider>
          </StripeProvider>
        </PaperProvider>
      </SafeAreaProvider>
    </Provider>
  );
};

const styles = StyleSheet.create({
  safeAreaChild: {
    flex: 1,
  },
  baseStyling: {
    fontFamily: 'TitilliumWeb-Regular',
  },
  screenCaptureView: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

setup({ storekitMode: 'STOREKIT2_MODE' });
export default withIAPContext(App);
