import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import rootReducer from '../reducers';
import { referenceDataApi } from '../api/referenceDataApi';
import { appointmentsApi } from '../api/appointmentsApi';
import { meApi } from '../api/meApi';
import { requestsApi } from '../api/requestsApi';
import { insuranceApi } from '../api/insuranceApi';

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      referenceDataApi.middleware,
      appointmentsApi.middleware,
      meApi.middleware,
      requestsApi.middleware,
      insuranceApi.middleware
    ),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
