import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './baseQuery';
import { ConsultationsIndexRequest } from '~/api/models/consultations/requests/ConsultationsIndexRequest';
import { ConsultationsPaginatedIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';

export const insuranceApi = createApi({
  reducerPath: 'insuranceApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    insuranceClaimsList: builder.query<ConsultationsPaginatedIndexResponse, ConsultationsIndexRequest>({
      query: (props) => ({
        url: '/consultations',
        method: 'get',
        params: {
          exclude: 'calendarEvents',
          ...props,
        },
        data: undefined,
        headers: undefined,
      }),
    }),
  }),
});

export const { useLazyInsuranceClaimsListQuery } = insuranceApi;
