import { axiosClient } from './client';
import { onRequestFulfilled, onRequestError, onResponseFulfilled, onResponseError } from './interceptors';

export { storeToken as setToken, getToken, userLogout, fullLogout, systemLogout } from './interceptors';

export * from './verbs';
export * from './client';

axiosClient.interceptors.request.use(onRequestFulfilled, onRequestError);
axiosClient.interceptors.response.use(onResponseFulfilled, onResponseError(axiosClient));
