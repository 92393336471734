import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './baseQuery';
import { ConsultationsIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { CalendarEventResponse } from '~/api/models/calendar/responses/CalendarEventResponse';
import moment from 'moment';
import { LARAVEL_DATE_TIME_SHORT } from '~/utils/dateAndTime';

export const appointmentsApi = createApi({
  reducerPath: 'appointmentsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Ongoing', 'TodayAppointments'],
  endpoints: (builder) => ({
    todayCalendarEvents: builder.query<CalendarEventResponse, void>({
      query: () => {
        const from = moment().startOf('day').format(LARAVEL_DATE_TIME_SHORT);
        const to = moment().endOf('day').format(LARAVEL_DATE_TIME_SHORT);
        return {
          url: 'calendar/events',
          method: 'get',
          params: {
            filter: { type: ['consultation', 'unavailability'].join(',') },
            to,
            from,
          },
          data: undefined,
          headers: undefined,
        };
      },
      providesTags: [{ type: 'TodayAppointments' }],
    }),
    onGoingAppointments: builder.query<ConsultationsIndexResponse, { includeEnded: boolean }>({
      query: (props) => ({
        url: 'consultations',
        method: 'get',
        params: {
          exclude: 'insuranceClaim,calendarEvents,clinicalTerms',
          filter: {
            state: props.includeEnded
              ? [ConsultationStateEnum.Ended, ConsultationStateEnum.Started]
              : [ConsultationStateEnum.Started],
          },
          limit: -1,
          sort: '-created_at',
        },
        data: undefined,
        headers: undefined,
      }),
      providesTags: [{ type: 'Ongoing' }],
    }),
  }),
});

export const { useLazyOnGoingAppointmentsQuery, useLazyTodayCalendarEventsQuery } = appointmentsApi;
