import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationsIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import { RootState } from '~/redux/reducers';
import { APPEND_REQUESTS } from '~/redux/reducers/requestsReducer';
import { useAppDispatch } from '~/redux/store';
import { isDoctorVersion, isClinicVersion } from '~/utils/buildConfig';

export function useClinicRequestsList() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const requests = useSelector((state: RootState) => state.requestsReducer.requests);

  const getRequests = () => {
    if (!isDoctorVersion() && !isClinicVersion()) return;
    setLoading(true);
    return apiGetConsultationsIndex<ConsultationsIndexResponse>({
      exclude: ['insuranceClaim', 'calendarEvents'],
      limit: -1,
      filter: {
        state: [
          ConsultationStateEnum.RequestSubmitted,
          ConsultationStateEnum.PendingPayment,
          ConsultationStateEnum.Scheduled,
        ],
      },
      sort: '-request_submitted_at',
    }).then((res) => {
      dispatch(APPEND_REQUESTS(res.data));
      setLoading(false);
    });
  };

  return { requests, loading, getRequests };
}
