import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View } from 'react-native';

import { useSumSubApplicantData } from '../../api/hooks/SumSubApplicantData';
import { useUserDetails } from '../../api/hooks/accounts/UserDetails';
import { Button } from '../../components/commonButton';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { IdentificationDocumentTypes, IdentificationDocumentsEnum } from '../../constants/documents';
import { genderList } from '../../services/config';
import {
  requiredValidation,
  validationSchema,
  alphabetsValidation,
  maxLengthValidation,
  alphabetCharactersValidation,
  minLengthValidation,
} from '../../services/validationConfig';
import { isDoctorVersion, isPatientVersion, isPharmacyVersion, whenAppType } from '../../utils/buildConfig';
import { labels } from '../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useFinaliseRegistration } from '~/api/hooks/accounts/FinaliseRegistration';
import { useTryUpgradeToPremium } from '~/api/hooks/insurance/TryUpgradePremiumHook';
import { usePharmacyStoredLinkingDetails } from '~/api/hooks/pharmacy/PharmacyLinkingAction';
import { GenderTypeEnum } from '~/api/models/common/constants/GenderTypeEnum';
import { apiUpdateMyAccount } from '~/api/services/authorisation';
import { SuccessAlert } from '~/common/commonMethods';
import FloatingInput from '~/components/inputs/FloatingInput';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { useInsuranceCompanyNames } from '~/components/patient/insurance/hooks/useCompanyNames';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { UPDATE_USER_ACCOUNT } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { convertToSentenceCase } from '~/utils/stringUtil';

const PersonalDetails: React.FC = () => {
  const sumSubApplicantDetails = useSumSubApplicantData();
  const { userDetails } = useUserDetails();
  const { current: isPatientApp } = useRef(isPatientVersion());
  const { current: isDoctorApp } = useRef(isDoctorVersion());
  const { current: isPharmacyApp } = useRef(isPharmacyVersion());
  const { updateRegistrationStatus } = useRegistrationSteps();
  const { finaliseRegistration } = useFinaliseRegistration();
  const { hasLinkingDetails } = usePharmacyStoredLinkingDetails();
  const { insuranceCompanies } = useInsuranceCompanyNames();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const { tryUpgradeWithInsurance } = useTryUpgradeToPremium();

  const { handleSubmit, control, setValue, watch } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: convertToSentenceCase(
        sumSubApplicantDetails?.fixedInfo?.firstName || sumSubApplicantDetails?.info?.firstName || ''
      ),
      lastName: convertToSentenceCase(
        sumSubApplicantDetails?.fixedInfo?.lastName || sumSubApplicantDetails?.info?.lastName || ''
      ),
      dob: '',
      title: '',
      document_type: null,
      document_number: '',
      gender: null,
      insuranceCompany: null,
      insurancePolicy: '',
    },
  });

  useEffect(() => {
    if (sumSubApplicantDetails) {
      const firstName = sumSubApplicantDetails.fixedInfo?.firstName || sumSubApplicantDetails.info?.firstName || '';
      const lastName = sumSubApplicantDetails.fixedInfo?.lastName || sumSubApplicantDetails.info?.lastName || '';
      setValue('firstName', convertToSentenceCase(firstName));
      setValue('lastName', convertToSentenceCase(lastName));
      if (!watch('dob')) setValue('dob', sumSubApplicantDetails.info?.dob || '');
    }
  }, [sumSubApplicantDetails]);

  useEffect(() => {
    if (userDetails?.account) {
      if (userDetails.account.national_id_number || userDetails.account.passport_number) {
        setValue(
          'document_type',
          userDetails.account.national_id_number
            ? IdentificationDocumentsEnum.IDENTITY_CARD
            : IdentificationDocumentsEnum.PASSPORT
        );
        setValue('document_number', userDetails.account.national_id_number || userDetails.account.passport_number);
      }

      if (userDetails.account.date_of_birth) {
        setValue('dob', userDetails.account.date_of_birth);
      }
    }
  }, [userDetails]);

  const onSubmit = async (details: {
    firstName: string;
    lastName: string;
    title: string;
    gender: GenderTypeEnum;
    document_type: IdentificationDocumentsEnum;
    document_number: string;
    dob: string;
    insuranceCompany?: number;
    insurancePolicy?: string;
  }) => {
    try {
      setLoading(true);

      const date_of_birth = whenAppType({
        whenPatient: userDetails.account.date_of_birth ?? sumSubApplicantDetails?.info?.dob,
        else: userDetails.account.date_of_birth ?? details.dob,
      });

      const data = {
        email: userDetails.account.email,
        country_code: userDetails.account.country_code,
        mobile_number: userDetails.account.mobile_number,
        first_name: details.firstName,
        last_name: details.lastName,
        title: details.title,
        passport_number:
          details.document_type === IdentificationDocumentsEnum.PASSPORT
            ? details.document_number
            : userDetails.account.passport_number,
        national_id_number:
          details.document_type === IdentificationDocumentsEnum.IDENTITY_CARD
            ? details.document_number
            : userDetails.account.national_id_number,
        gender: details.gender,
        date_of_birth,
      };

      const res = await apiUpdateMyAccount(data);
      if (res.data) dispatch(UPDATE_USER_ACCOUNT(res.data));

      if (isPatientVersion() && details.dob && details.insuranceCompany && details.insurancePolicy) {
        if (await tryUpgradeWithInsurance({ ...details, createPolicy: true })) {
          SuccessAlert(
            ['Your account has been upgraded to Digimed Premium as part of your insurance plan.'],
            'Your insurance plan includes Digimed Premium!'
          );
        }
      }

      if (isPharmacyVersion() && hasLinkingDetails) {
        await finaliseRegistration();
      } else {
        await updateRegistrationStatus();
      }
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const buttons = [<Button funCallback={handleSubmit(onSubmit)} label={labels.continue} disabled={loading} />];

  return (
    <EnhancedRegistrationLayout
      buttons={buttons}
      title={labels.personalDetailsScreenTitle}
      subtitle={labels.personalDetailsScreenMessage}>
      <View style={{ width: '100%' }}>
        {isDoctorApp ? (
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <FloatingInput
                maxLength={validationSchema.title.maxLength}
                label={labels.legalTitle}
                value={value}
                onChangeValue={onChange}
                onBlur={onBlur}
                error={isSubmitted && !!error}
                errorMessage={isSubmitted && error?.message}
              />
            )}
            rules={{
              validate: { alphabetsValidation },
              maxLength: maxLengthValidation(validationSchema.title.maxLength),
            }}
          />
        ) : null}

        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              maxLength={validationSchema.name.maxLength}
              label={labels.legalFirstName}
              showMandatory
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
            />
          )}
          rules={{
            validate: { alphabetCharactersValidation },
            required: requiredValidation(labels.legalFirstName),
          }}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              maxLength={validationSchema.lastName.maxLength}
              label={labels.legalLastName}
              showMandatory
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
            />
          )}
          rules={{
            validate: { alphabetCharactersValidation },
            required: requiredValidation(labels.legalLastName),
          }}
        />

        {!isPharmacyApp ? (
          <>
            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <Dropdown
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                  list={genderList.map((item) => ({ label: item.name, value: item.id }))}
                  value={value}
                  setValue={onChange}
                  label={labels.gender}
                />
              )}
            />

            {isDoctorApp ? (
              <Controller
                name="document_type"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <Dropdown
                    list={IdentificationDocumentTypes}
                    setValue={onChange}
                    value={value}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    label={labels.documentType}
                    showMandatory
                  />
                )}
                rules={{
                  required: requiredValidation(labels.documentType),
                }}
              />
            ) : null}

            {isDoctorApp || watch('document_number') ? (
              <Controller
                name="document_number"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    showMandatory
                    maxLength={validationSchema.passportOrIdCard.maxLength}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    label={labels.idCardOrPassport}
                    disabled={isPatientApp}
                  />
                )}
                rules={{
                  minLength: minLengthValidation(validationSchema.passportOrIdCard.minLength),
                  required: requiredValidation(labels.idCardOrPassport),
                }}
              />
            ) : null}

            {isDoctorApp || watch('dob') ? (
              <Controller
                name="dob"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <DatePicker
                    showMandatory
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    onChangeValue={onChange}
                    value={value}
                    label={labels.dateOfBirthPlaceholder}
                    readonly={isPatientApp}
                  />
                )}
                rules={{
                  required: requiredValidation('Date of birth'),
                }}
              />
            ) : null}
          </>
        ) : null}

        {isPatientApp ? (
          <>
            <Controller
              name="insuranceCompany"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Dropdown
                  list={insuranceCompanies.map((item) => ({ label: item.name, value: item.id }))}
                  setValue={onChange}
                  value={value}
                  label={labels.patient.insuranceCompany}
                  searchable
                />
              )}
            />

            <Controller
              name="insurancePolicy"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                  label={labels.patient.insurancePolicy}
                />
              )}
            />
          </>
        ) : null}
      </View>
    </EnhancedRegistrationLayout>
  );
};

//make this component available to the app
export default PersonalDetails;
